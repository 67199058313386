import React from 'react'
import { Link } from 'gatsby'
import wave from '../../static/wave-line.svg'

import Layout from '../components/Layout.js'

export default ({ data, pageContext }) => {
  return (
    <Layout>
      <h1 class="post__title center-text negative-margin">Topics</h1>
      <img src={wave} alt="waves" class="post__line negative-margin" />
      <ul>
        {pageContext.categories.map(category => {
          const path = `/topics/${category}`

          return (
            <li class="category-link" key={category}>
              <Link to={path}>{category}</Link>
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}
